import React from 'react';

const TayMarDetailing = () => {
    return (
        <div className="min-h-screen pt-[15vh] bg-gradient-to-r from-blue-900 to-grey-400 flex items-center justify-center p-4">
            <div className="max-w-2xl bg-white rounded-lg shadow-xl p-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                    Mobile Detailing Special Promotion
                </h1>
                <p className="text-gray-700 mb-6">
                    Experience the convenience and savings with TayMar auto detailing service. For a limited time, we're offering an exclusive promotional pricing while supplies last. No hassle - we'll come to you and wash your car. See the savings below!
                </p>

                {/* Tier 1 - Basic Wash / Detail */}
                <div className="bg-gray-100 rounded-lg p-6 mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">Basic Package</h2>
                    <div className="flex justify-between items-center mb-4">
                        <span className="font-semibold text-gray-800">Regular Price:</span>
                        <span className="text-xl font-bold text-gray-500 line-through">$50</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <span className="font-semibold text-gray-800">Discounted Price:</span>
                        <span className="text-xl font-bold text-green-600">$20</span>
                    </div>
                    <p className="text-gray-700 mb-4">
                        Our basic package includes the essentials to get your car looking fresh.
                    </p>
                    <ul className="list-disc list-inside text-gray-700 mb-6">
                        <li>Exterior body and tire wash</li>
                        <><p>Or </p></>
                        <li>Interior vacuuming</li>
                        <li>Window cleaning</li>
                        <li>Dashboard and console wipe down</li>
                    </ul>
                    <p className="text-green-600 font-semibold">You save $30!</p>
                </div>

                {/* Tier 2 - Premium Wash + Detail */}
                <div className="bg-gray-100 rounded-lg p-6 mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">Premium Package</h2>
                    <div className="flex justify-between items-center mb-4">
                        <span className="font-semibold text-gray-800">Regular Price:</span>
                        <span className="text-xl font-bold text-gray-500 line-through">$85</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <span className="font-semibold text-gray-800">Discounted Price:</span>
                        <span className="text-xl font-bold text-green-600">$45</span>
                    </div>
                    <p className="text-gray-700 mb-4">
                        Our premium package includes everything from the basic package plus extra care for your vehicle.
                    </p>
                    <ul className="list-disc list-inside text-gray-700 mb-6">
                        <li>Everything in the Basic Package</li>
                        <li>Full interior wipe down</li>
                        <li>Rims and tires cleaning</li>
                        <li>Exterior wax (for added shine and protection)</li>
                    </ul>
                    <p className="text-green-600 font-semibold">You save $40!</p>
                </div>

                {/* Tier 3 - Luxury Wash + Detail + Protective Coats */}
                <div className="bg-gray-100 rounded-lg p-6 mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">Luxury Package</h2>
                    <div className="flex justify-between items-center mb-4">
                        <span className="font-semibold text-gray-800">Regular Price:</span>
                        <span className="text-xl font-bold text-gray-500 line-through">$125</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <span className="font-semibold text-gray-800">Discounted Price:</span>
                        <span className="text-xl font-bold text-green-600">$75</span>
                    </div>
                    <p className="text-gray-700 mb-4">
                        Our luxury package is the ultimate care for your vehicle, including protective coatings to keep your car shining longer.
                    </p>
                    <ul className="list-disc list-inside text-gray-700 mb-6">
                        <li>Everything in the Premium Package</li>
                        <li>Paint protection coating (for long-lasting shine)</li>
                        <li>Protective wax for added durability</li>
                        <li>Engine bay cleaning</li>
                        <li>Leather conditioning (if applicable)</li>
                    </ul>
                    <p className="text-green-600 font-semibold">You save $50!</p>
                </div>

                <h2 className="text-2xl font-bold text-gray-800 mb-4">Why Choose Our Mobile Detailing Service?</h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
                    <li>Convenient, on-location service that saves you time and hassle.</li>
                    <li>Professional detailing that protects and enhances your vehicle’s appearance.</li>
                    <li>Affordable pricing that covers your costs while offering great value.</li>
                    <li>Hassle free signup - just give us call or email providing your name, vehicle, location, and a few questions to determine your vehicle's needs then you're scheduled!</li>
                </ul>
                <div className="text-center">
                    <a href='/detailquote'>
                        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded shadow-lg transition duration-200">
                            Book Your Appointment Now
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TayMarDetailing;