import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import io from 'socket.io-client';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

const socket = io('https://pickup-api.onrender.com'); // adjust the URL as needed

const DetailQuoteForm = () => {
  const [step, setStep] = useState(1);
  const [services, setServices] = useState({
    basic: false,
    premium: false,
    elite: false,
    petHair: false,
    hotMess: false,
  });
  const [contact, setContact] = useState({ name: '', email: '', phone: '', streetAddress: '', zipCode:'' });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);

  // Fetch initial availability from backend
  useEffect(() => {
    fetch('/api/availability')
      .then((res) => res.json())
      .then((data) => setAppointments(data))
      .catch((err) => console.error("Error fetching availability:", err));
  }, []);

  // Socket.IO: Listen for live updates
  useEffect(() => {
    socket.on('availabilityUpdate', (data) => {
      setAppointments(data);
    });
    return () => socket.off('availabilityUpdate');
  }, []);

  // Create an array of Date objects representing booked days
  const blockedDates = appointments.map(app => {
    // app.date is in "mm/dd/yyyy" format
    const [month, day, year] = app.date.split('/');
    return new Date(year, month - 1, day);
  });

  const handleServiceChange = (e) => {
    const { name, checked } = e.target;
    setServices((prev) => ({ ...prev, [name]: checked }));
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContact((prev) => ({ ...prev, [name]: value }));
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = (e) => {
    e.preventDefault();

    const quoteData = {
      services,         // The service selected by the user
      contact,          // The user's contact information
      scheduledDate: selectedDate,  // The time the user wants the service
    };


    // Send the complete quote data to the backend
    fetch('https://pickup-api.onrender.com/api/schedule', {  // Correct route
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(quoteData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.appointment) {
          Swal.fire({
            icon: 'success',
            title: 'Submission Successful!',
            text: 'Your quote request has been submitted!',
          });

          // Reset the form
          setServices([]);
          setContact('');
          setSelectedDate('');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error submitting your quote. Please try again.',
          });
        }
      })
      .then(() => {
        window.location.href = '/detailthanks'; // Redirect to the first screen
      })
      .catch((err) => {
        console.error('Submission error:', err);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an error submitting your quote. Please try again.',
        });
      });
  };

  // Define this function in your component (outside the return block)
  const handleStep2Next = (e) => {
    const form = e.target.closest("form");
    if (form.checkValidity()) {
      nextStep();
    } else {
      // This will trigger the browser's built‑in validation messages
      form.reportValidity();
    }
  };


  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-10 px-4">
      <div className="w-full max-w-3xl bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-6">Get Your Detailing Quote</h1>
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Select Your Services</h2>
              <p className="mb-4">Choose one or more service tiers:</p>
              <div className="space-y-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="basic"
                    checked={services.basic}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>Basic Detail</strong> (Regular: $50, Promo: $25) – Interior vacuuming, dusting, and quick wipe down.
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="premium"
                    checked={services.premium}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>Premium Detail</strong> (Regular: $85, Promo: $45) – Includes Basic plus carpet/upholstery shampoo and deep cleaning.
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="elite"
                    checked={services.elite}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    <strong>Elite Detail</strong> (Regular: $125, Promo: $75) – Includes Premium plus full-service interior & exterior wash.
                  </span>
                </label>
              </div>
              <h3 className="text-xl font-semibold mt-6 mb-2">Optional Add‑Ons</h3>
              <div className="space-y-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="petHair"
                    checked={services.petHair}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Pet Hair Removal (additional cost based on vehicle size)
                  </span>
                </label>
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="hotMess"
                    checked={services.hotMess}
                    onChange={handleServiceChange}
                    className="h-5 w-5 text-indigo-600"
                  />
                  <span className="text-lg">
                    Hot Mess Cleanup (upcharge based on the level of extra work)
                  </span>
                </label>
              </div>
              <div className="mt-8 flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    // Validate that at least one core service is selected
                    if (!services.basic && !services.premium && !services.elite) {
                      alert("Please select at least one service.");
                      return;
                    }
                    nextStep();
                  }}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-lg mb-1">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={contact.name}
                    onChange={handleContactChange}
                    required
                    pattern=".*[A-Za-z]+.*"
                    title="Name must contain at least one letter."
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-lg mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={contact.email}
                    onChange={handleContactChange}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    title="Please enter a valid email address (e.g., user@mail.com)."
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-lg mb-1">Phone</label>
                  <InputMask
                    mask="(999) - 999 - 9999"
                    maskChar=""
                    type="tel"
                    name="phone"
                    value={contact.phone}
                    onChange={handleContactChange}
                    required
                    title="Phone number must be in the format (777) - 777 - 7777."
                    placeholder="(777) - 777 - 7777"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-lg mb-1">Street Address</label>
                  <input
                    type="text"
                    name="streetAddress"
                    value={contact.streetAddress}
                    onChange={handleContactChange}
                    required
                    placeholder="Enter Street Address"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-lg mb-1">Zip Code</label>
                  <input
                    type="text"
                    name="zipCode"
                    value={contact.zipCode}
                    onChange={handleContactChange}
                    required
                    pattern="\d{5}(-\d{4})?"
                    title="Please enter a valid zip code (e.g., 12345 or 12345-6789)."
                    placeholder="Enter Zip Code"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={handleStep2Next}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Schedule Your Service</h2>
              <p className="mb-4 text-lg">Select your preferred date and time:</p>
              <div className="mb-6">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={new Date()}
                  excludeDates={blockedDates}
                  className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={nextStep}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Review
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="animate-fadeIn">
              <h2 className="text-2xl font-semibold mb-4">Review &amp; Submit</h2>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Selected Services:</h3>
                <ul className="list-disc list-inside">
                  {services.basic && <li>Basic Detail</li>}
                  {services.premium && <li>Premium Detail</li>}
                  {services.elite && <li>Elite Detail</li>}
                  {services.petHair && <li>Pet Hair Removal Add‑On</li>}
                  {services.hotMess && <li>Hot Mess Cleanup Add‑On</li>}
                </ul>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Contact Information:</h3>
                <p className="text-lg">{contact.name} | {contact.email} | {contact.phone}</p>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Location:</h3>
                <p className="text-lg">{contact.streetAddress} | {contact.zipCode}</p>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Scheduled Date/Time:</h3>
                <p className="text-lg">{selectedDate.toString()}</p>
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded"
                >
                  Submit Quote Request
                </button>
              </div>
            </div>
          )}

        </form>
      </div>
    </div>
  );

};

export default DetailQuoteForm;
